import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function logisticsorderUseList() {
  // Use toast
  const toast = useToast()
  const condition = ref({})
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: ''},
    { key: 'id', label: 'ID', sortable: true },
    { key: 'order_no', label: '订单编号', sortable: true },
    { key: 'customer_name', label: '客户名称', sortable: true },
    { key: 'product_name', label: '货物名称', sortable: true },
    { key: 'packages', label: '件数', sortable: true },
    { key: 'packaging_type', label: '包装形式', sortable: true },
    { key: 'weight', label: '重量(T)', sortable: true },
    { key: 'bulk', label: '体积(M³)', sortable: true },
    { key: 'address_fix', label: '装车地址', sortable: true },
    { key: 'address_unload', label: '卸货地址', sortable: true },
    { key: 'order_date', label: '订单日期', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'total_amount', label: '总费用', sortable: true },
    { key: 'transport_fee', label: '运费', sortable: true },
    { key: 'tax_fee', label: '税金', sortable: true },
    { key: 'other_fee', label: '其他费用', sortable: true },
    { key: 'receivable_fee', label: '应收运费', sortable: true },
    { key: 'pay_method', label: '付款方式', sortable: true },
    { key: 'pre_pay', label: '预付金额', sortable: true },
    { key: 'receipt_pay', label: '回单支付金额', sortable: true },
    { key: 'one_pay', label: '一次性支付金额', sortable: true },
    { key: 'carriager', label: '承运人', sortable: true },
    { key: 'car_type', label: '车辆类型', sortable: true },
    { key: 'car_no', label: '车牌号', sortable: true },
    { key: 'car_cycle', label: '车型', sortable: true },
    { key: 'car_driver', label: '司机姓名', sortable: true },
    { key: 'car_tel', label: '司机电话', sortable: true },
    { key: 'updator', label: '操作人', sortable: true },
    { key: 'modify_time', label: '操作日期', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('order_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('logisticsorder/search', {
        customer_name:condition.value.customer_name,
        carriager:condition.value.carriager,
        car_no:condition.value.car_no,
        date:condition.value.date,
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    refetchData,

    // Extra Filters
  }
}
